import React, { Component } from "react";

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error: null };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: false, error };
    }
  
    componentDidCatch(error, errorInfo) {
      console.error("Error:", error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return (
          <div>
            <h5>Oops, something went wrong!</h5>
            <p>{this.state.error.message}</p>
            <p>You may have to refresh the entire app to recover</p>
          </div>
        );
      }
      return this.props.children;
    }
  }
  
export default ErrorBoundary;
