import React from "react";
import { Nav, Row, Col, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();

  const handleLogout = (setUserInfo) => {
    setUserInfo((prev) => {});
    navigate("/login");
  };

  const handleSwitchAccount = (setUserInfo, accountNumber) => {
    console.log(`Switching to account ${accountNumber}`);
    setUserInfo((prev) => ({
      ...prev,
      client_account_id: accountNumber,
      account_name: prev.related_accounts[accountNumber].name,
    }));
  };

  const availableAccounts = props.userInfo?.available_accounts;
  const currentAccount = props.userInfo?.account_name || "";

  return (
    <div style={{ paddingBottom: "10px" }}>
      <Row>
        <Col>
          <h1 style={{ paddingLeft: "20px" }}>
            EDA Framework {currentAccount}
          </h1>
        </Col>
        <Col className="d-flex justify-content-end">
          <Nav
            className="header-nav"
            variant="pills tabs"
            defaultActiveKey="/schemas"
          >
            <Dropdown>
                <Dropdown.Toggle
                  activeClassName="active"
                  id="settings-dropdown"
                  style={{
                    backgroundColor: "black",
                    color: "#AAABB8",
                    fontSize: "18px",
                    border: "none",
                  }}
                >
                  Datalake
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-dropdown-menu">
                  <Nav.Item>
                    <Nav.Link as={NavLink} to="/schemas" activeClassName="active">
                      Schemas
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={NavLink} to="/tables" activeClassName="active">
                      Tables
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={NavLink}
                      to="/pipelineexecutions"
                      activeClassName="active"
                    >
                      Pipeline Executions
                    </Nav.Link>
                  </Nav.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle
                  activeClassName="active"
                  id="settings-dropdown"
                  style={{
                    backgroundColor: "black",
                    color: "#AAABB8",
                    fontSize: "18px",
                    border: "none",
                  }}
                >
                  Dataquality
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-dropdown-menu">
                  <Nav.Item>
                    <Nav.Link as={NavLink} to="/dataquality" activeClassName="active">
                      Dataquality
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={NavLink} to="/dataqualityresults" activeClassName="active">
                      Dataquality Evaluations
                    </Nav.Link>
                  </Nav.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle
                  activeClassName="active"
                  id="settings-dropdown"
                  style={{
                    backgroundColor: "black",
                    color: "#AAABB8",
                    fontSize: "18px",
                    border: "none",
                  }}
                >
                  Integrations
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-dropdown-menu">
                  <Nav.Item>
                    <Nav.Link as={NavLink} to="/integrationgroups" activeClassName="active">
                      Integration Groups
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={NavLink} to="/integrations" activeClassName="active">
                      Integrations
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={NavLink} to="/integrationpipelineexecutions" activeClassName="active">
                      Integration Pipeline Executions
                    </Nav.Link>
                  </Nav.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/secrets" activeClassName="active">
                Secrets
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Dropdown>
                <Dropdown.Toggle
                  activeClassName="active"
                  id="settings-dropdown"
                  style={{
                    backgroundColor: "black",
                    color: "#AAABB8",
                    fontSize: "18px",
                    border: "none",
                  }}
                >
                  Account Settings
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-dropdown-menu">
                  <Dropdown.Item
                    as={NavLink}
                    to="/settings/access"
                    activeClassName="active"
                  >
                    Access
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="/settings/notifications"
                    activeClassName="active"
                  >
                    Notifications
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="/settings/settings"
                    activeClassName="active"
                  >
                    Settings
                  </Dropdown.Item>
                  <Dropdown>
                    <Dropdown.Toggle
                      activeClassName="active"
                      id="select-account-dropdown"
                      style={{
                        backgroundColor: "black",
                        color: "#AAABB8",
                        fontSize: "18px",
                        border: "none",
                      }}
                    >
                      Switch Account
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="account-dropdown-menu">
                      {availableAccounts &&
                        availableAccounts.length > 0 &&
                        availableAccounts.map((accountNumber) => (
                          <Dropdown.Item
                            key={accountNumber}
                            onClick={() =>
                              handleSwitchAccount(
                                props.setUserInfo,
                                accountNumber,
                              )
                            }
                          >
                            Switch to Account {accountNumber}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown.Item
                    onClick={() => handleLogout(props.setUserInfo)}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
