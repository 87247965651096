import { Button } from "react-bootstrap";

export const StandardActionButton = ({ callback, text }) => {
  return (
    <>
      <Button
        style={{ marginRight: "1vw", width: "15vw" }}
        variant="primary"
        size="lg"
        onClick={callback}
      >
        {text}
      </Button>
    </>
  );
};
