import { edaPost } from "../utils/api/callapi";

export const fetchAggridData = async (
  axiosInstance,
  API_ROUTE,
  postData,
  dataField,
) => {
  try {
    const response = await edaPost(axiosInstance, API_ROUTE, postData);
    console.log(response);
    return response.data[dataField];
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const formatDataAggrid = (rowData, excludeKeys) => {
  excludeKeys = excludeKeys || [];
  const allKeys = new Set();
  const formattedData = rowData.map((item) => {
    const entries = Object.entries(item);
    entries.forEach(([key, value]) => {
      if (!excludeKeys.includes(key)){
        allKeys.add(key);
      }
      item[key] = value !== undefined ? value : null;
    });
    return Object.fromEntries(entries);
  });
  return { formattedData, allKeys };
};

export const generateAggridColumnDefs = (keys, BOOLEAN_COLUMNS, sortColumn, sortOrder) => {
  sortColumn = sortColumn || false;
  sortOrder = sortOrder || "asc";
  return keys.map((key) => {
    const base = {
      columnName: key,
      field: key,
      headerName: key.toLocaleLowerCase(),
      valueGetter: (params) => {
        const value = params.data[key];
        if (typeof value === "object") {
          return JSON.stringify(value);
        }
        return value !== undefined ? value : null;
      },
    };
    if (BOOLEAN_COLUMNS.includes(key)) {
      base.cellDataType = "boolean";
    }
    if (sortColumn && key === sortColumn){
        base.sort = sortOrder;
    }
    return base;
  });
};

export const orderKeysAggrid = (preferredOrder, allKeys) => {
  const filteredKeys = preferredOrder.filter((key) => allKeys.has(key));
  const remainingKeys = Array.from(allKeys).filter(
    (key) => !preferredOrder.includes(key),
  );
  return [...filteredKeys, ...remainingKeys];
};
