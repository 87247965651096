import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import axios from "axios";

import createAxiosInstance from "../utils/api/axiosInstance";

const getToken = async (username, password) => {
  const postData = {
    auth_params: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  const response = await axios.post(
    `${process.env.REACT_APP_EDA_API_BASE_URL}/auth`,
    postData,
  );
  return response.data.token;
};

export const refreshUser = (setUserInfo, userInfo) => {
  const accessToken = getToken(userInfo.username, userInfo.password);
  const user = {
    ...userInfo,
    isAuthenticated: true,
    axiosInstance: createAxiosInstance(accessToken),
  };

  setUserInfo((prev) => user);
};

function LoginPage(props) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      const accessToken = await getToken(username, password);

      const instance = createAxiosInstance(accessToken);

      const userStuff = await instance
        .post(`${process.env.REACT_APP_EDA_API_BASE_URL}/auth/userinfo`, {})
        .then((response) => response.data.user_information);
      console.log(userStuff); // this needs to be fixed. They need to just pass the token no account id.

      const availableAccounts = Object.keys(userStuff.access_levels);
      ///
      /// change this when done testing
      let currentAccount;
      availableAccounts.forEach(account => {
        if (["390602748859", "176350427855"].includes(account)){
           currentAccount = account;
        }
      })
      ///
      ///

      const accountStuff = await instance
        .post(`${process.env.REACT_APP_EDA_API_BASE_URL}/account`, {
          client_account_id: currentAccount,
          settings: ["related_accounts", "account_name", "organization_name"],
        })
        .then((response) => {
          let outputObject = {};
          response.data.settings.forEach((obj) => {
            outputObject[obj.setting] = obj.value;
          });
          console.log(outputObject);
          return outputObject;
        });

      const userInfo = {
        isAuthenticated: true,
        axiosInstance: instance,
        client_account_id: currentAccount,
        available_accounts: availableAccounts,
        username: username,
        password: password,
        ...accountStuff,
        ...userStuff,
      };

      props.setUserInfo((prev) => userInfo);
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <Container>
      <Row className="px-4 my-5">
        <Col>
          <h1>Login</h1>
        </Col>
      </Row>
      <Row className="px-4 my-5">
        <Col sm={6}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter USERNAME"
                onChange={(evt) => setUsername(evt.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                minLength="8"
                placeholder="Enter PASSWORD"
                onChange={(evt) => setPassword(evt.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="button" onClick={handleLogin}>
              Login &gt;&gt;
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
