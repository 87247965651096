export function getChangeSet(original, changed) {
  const differences = {};

  for (let key in original) {
    if (original.hasOwnProperty(key)) {
      if (changed.hasOwnProperty(key) && original[key] !== changed[key]) {
        differences[key] = changed[key];
      }
    }
  }
  return differences;
}

export const downloadJsonFile = (data, filename) => {
  const json = JSON.stringify(data);
  const blob = new Blob([json], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "data.json";
  a.click();
  URL.revokeObjectURL(url);
};

function dynamicKeyJoin(obj, keysToJoin, separator = "-") {
  return keysToJoin.map((key) => obj[key]).join(separator);
}

function listToIndexedObject(list, keys) {
  console.log(list);
  return list.reduce((acc, item) => {
    const key = dynamicKeyJoin(item, keys);
    acc[key] = item;
    return acc;
  }, {});
}

export const leftJoinObjectLists = (list1, list2, keys) => {
  const index = listToIndexedObject(list2, keys);
  return list1.map((item) => ({
    ...item,
    ...index[dynamicKeyJoin(item, keys)], // Combine with matching item from list2
  }));
};
