import React, { useEffect, useState, useRef } from "react";
import { Button, Card } from "react-bootstrap";

const DynamicJsonForm = ({
  title,
  initialJsonData,
  callback,
  allowAddField,
  nonEditableFields,
  largeTextBoxKeys,
}) => {
  nonEditableFields = nonEditableFields || [];
  largeTextBoxKeys = largeTextBoxKeys || [];

  const modifiedInitialJsonData = {};
  for (const key in initialJsonData) {
    const value = initialJsonData[key];
    if (Array.isArray(value)) {
      modifiedInitialJsonData[`${key} {list}`] = value;
    } else if (typeof value === "object") {
      modifiedInitialJsonData[`${key} {object}`] = value;
    } else {
      modifiedInitialJsonData[key] = value;
    }
  }

  const [jsonData, setJsonData] = useState(modifiedInitialJsonData);
  const [newFieldName, setNewFieldName] = useState("");
  const [newFieldType, setNewFieldType] = useState("text");
  const [fieldKeys, setFieldKeys] = useState(
    Object.keys(modifiedInitialJsonData),
  );
  const formRef = useRef(null);

  useEffect(() => {
    if (initialJsonData) {
      setJsonData(modifiedInitialJsonData);
    }
  }, [initialJsonData]);

  const handleChange = (event) => {
    let { name, value, type } = event.target;
    setJsonData((prevData) => {
      let parsedValue;
      if (type === "checkbox") {
        parsedValue = event.target.checked;
      } else if (type === "number") {
        parsedValue = parseFloat(value);
      } else {
        parsedValue = value;
      }
      return { ...prevData, [name]: parsedValue };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);

    let formDataObject = {};
    formData.forEach((value, key) => {
      let realKey = key;
      const isObject = key.endsWith(" {object}");
      const isList = key.endsWith(" {list}");
      if (isObject) {
        realKey = key.slice(0, -9);
      } else if (isList) {
        realKey = key.slice(0, -7);
      }

      if (isObject) {
        formDataObject[realKey] = JSON.parse(value);
      } else if (isList) {
        formDataObject[realKey] = JSON.parse(value);
      } else {
        const parsedValue = !isNaN(value) ? parseFloat(value) : value;
        formDataObject[realKey] =
          typeof parsedValue === "string" ? parsedValue.trim() : parsedValue;
      }
    });

    nonEditableFields.forEach((key) => {
      formDataObject[key] = initialJsonData[key];
    });

    console.log(formDataObject);

    callback(formDataObject);
  };

  const handleAddField = () => {
    if (newFieldName.trim() !== "") {
      let fieldName = newFieldName;
      setJsonData((prevData) => {
        if (newFieldType === "list") {
          fieldName = fieldName + " {list}";
        } else if (newFieldType === "object") {
          fieldName = fieldName + " {object}";
        }
        return { ...prevData, [fieldName]: getDefaultFieldValue() };
      });
      setNewFieldName("");
    }
  };

  const handleRemoveField = (fieldName) => {
    setJsonData((prevData) => {
      const newData = { ...prevData };
      delete newData[fieldName];
      return newData;
    });
    setFieldKeys((prevKeys) => prevKeys.filter((key) => key !== fieldName));
  };

  const getDefaultFieldValue = () => {
    if (newFieldType === "boolean") {
      return false;
    } else if (newFieldType === "number") {
      return 0;
    } else {
      return "";
    }
  };

  const getInputType = (value, key) => {
    if (key && key.endsWith(" {object}")) {
      return "object";
    } else if (key && key.endsWith(" {list}")) {
      return "list";
    } else if (Array.isArray(value)) {
      return "list";
    } else if (typeof value === "object") {
      return "object";
    } else if (typeof value === "boolean") {
      return "checkbox";
    } else if (typeof value === "number") {
      return "number";
    } else {
      return "text";
    }
  };

  const getInputStyle = (key) => {
    if (largeTextBoxKeys.includes(key)) {
      return { width: "100%", minHeight: "200px" };
    }
    return { width: "100%", minHeight: "50px" };
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          width: "80%",
          padding: "20px",
          marginBottom: "20px",
          marginTop: "20px",
        }}
        className="bg-dark text-white"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3 style={{ textAlign: "center" }}>{title}</h3>
          <form ref={formRef} onSubmit={handleSubmit} style={{ width: "100%" }}>
            <table style={{ width: "100%", textAlign: "left" }}>
              <tbody>
                {Object.entries(jsonData).map(([key, value]) => (
                  <tr key={key}>
                    <td
                      style={{
                        paddingRight: "10px",
                        width: "30%",
                        minWidth: "120px",
                        textAlign: "right",
                      }}
                    >
                      <label htmlFor={key}>{key}</label>
                    </td>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      {getInputType(value) === "list" ||
                      getInputType(value) === "object" ? (
                        <textarea
                          id={key}
                          name={key}
                          value={JSON.stringify(value, null, 2)}
                          onChange={handleChange}
                          style={getInputStyle(key)}
                          disabled={nonEditableFields.includes(key)}
                        />
                      ) : getInputType(value) === "checkbox" ? (
                        <input
                          type="checkbox"
                          id={key}
                          name={key}
                          checked={value}
                          onChange={handleChange}
                          disabled={nonEditableFields.includes(key)}
                        />
                      ) : getInputType(value) === "number" ? (
                        <input
                          type="number"
                          id={key}
                          name={key}
                          value={value}
                          onChange={handleChange}
                          disabled={nonEditableFields.includes(key)}
                        />
                      ) : (
                        <textarea
                          id={key}
                          name={key}
                          value={value}
                          onChange={handleChange}
                          style={getInputStyle(key)}
                          disabled={nonEditableFields.includes(key)}
                        />
                      )}
                      <button
                        onClick={() => handleRemoveField(key)}
                        style={{
                          marginLeft: "10px",
                          alignSelf: "center",
                          cursor: "pointer",
                        }}
                        disabled={nonEditableFields.includes(key)}
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
                {allowAddField != false && (
                  <tr>
                    <td
                      style={{
                        paddingRight: "10px",
                        width: "30%",
                        minWidth: "120px",
                        textAlign: "right",
                      }}
                    >
                      <input
                        type="text"
                        value={newFieldName}
                        onChange={(e) => setNewFieldName(e.target.value)}
                        placeholder="New Field Name"
                      />
                    </td>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <select
                        value={newFieldType}
                        onChange={(e) => setNewFieldType(e.target.value)}
                      >
                        <option value="string">String</option>
                        <option value="number">Number</option>
                        <option value="boolean">Boolean</option>
                        <option value="list">List</option>
                        <option value="object">Object</option>
                      </select>
                      <Button
                        variant="quaternary"
                        onClick={handleAddField}
                        style={{
                          marginLeft: "10px",
                          alignSelf: "center",
                          cursor: "pointer",
                        }}
                      >
                        Add Field
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                style={{ width: "15vw" }}
                variant="quaternary"
                size="lg"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default DynamicJsonForm;
