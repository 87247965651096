import React, { useState } from "react";

function NumberInputForm({ title, callback }) {
  const [number, setNumber] = useState("");

  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const parsedNumber = parseInt(number, 10); // Parse the input value as an integer
    if (!isNaN(parsedNumber)) {
      callback(parsedNumber);
    } else {
      console.log("Invalid number");
    }
  };

  return (
    <div>
      <h4>{title}</h4>
      <form onSubmit={handleSubmit}>
        <label>
          Enter an integer:
          <input type="number" value={number} onChange={handleChange} />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default NumberInputForm;
