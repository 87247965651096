import { useState, useEffect } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import {
  edaPost,
  edaPut,
  handleAPIActionCall,
} from "../utils/api/callapi";


import DynamicJsonForm from "../components/forms/DynamicJsonForm";
import { StandardActionButton } from "../components/StandardActionButton";


function NotificationRecipientsPage({ userInfo, setSuccessMessages, setFailureMessages }) {
  const [
    notificationRecipientsDataSettings,
    setNotificationRecipientsDataSettings,
  ] = useState({});
  const [notificationRecipientsDataSNS, setNotificationRecipientsDataSNS] =
    useState({});
  const CLIENT_ACCOUNT_ID = userInfo.client_account_id;

  // --- api routes ---
  const GET_API_ROUTE = "notifications";
  const WRITE_API_ROUTE = "account/notifications";
  const FROM_SETTINGS_API_ROUTE = "notifications/fromsettingstable";
  // ^^^ api routes ^^^

  // --- on mount or account change ---
  useEffect(() => {
    reloadData();
  }, [userInfo]);
  // ^^^ on mount or account change ^^^


  // --- populating data ---
  const reloadData = async () => {
    console.log("in reload data");

    const postDataSettings = {
      client_account_id: userInfo.client_account_id,
      action: "get_settings_emails",
    };

    const postDataSNS = {
      client_account_id: userInfo.client_account_id,
      action: "get_topic_emails",
    };

    edaPost(userInfo.axiosInstance, GET_API_ROUTE, postDataSettings)
      .then((result) => {
        const updatedValues = { settings_emails: result.data.emails };
        setNotificationRecipientsDataSettings((prevData) => updatedValues);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    edaPost(userInfo.axiosInstance, GET_API_ROUTE, postDataSNS)
      .then((result) => {
        const updatedValues = {
          notification_email_recipients: result.data.emails,
        };
        setNotificationRecipientsDataSNS((prevData) => updatedValues);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // ^^^ populating data ^^^

  // --- trigger action button callbacks ---

  const updateNotificationRecipientsSettings = async (jsonData) => {
    console.log(jsonData);

    const putData = {
      client_account_id: CLIENT_ACCOUNT_ID,
      setting: "notification_emails",
      value: jsonData.settings_emails,
    };

    handleAPIActionCall(edaPut(userInfo.axiosInstance, WRITE_API_ROUTE, putData), setSuccessMessages, setFailureMessages);
    await reloadData();
  };

  const updateNotificationRecipientsFromSettings = async (event) => {
    const postData = {
      action: "update_emails_from_settings_table",
      client_account_id: CLIENT_ACCOUNT_ID,
    };

    handleAPIActionCall(edaPost(userInfo.axiosInstance, FROM_SETTINGS_API_ROUTE, postData), setSuccessMessages, setFailureMessages);
    await reloadData();
  };

  // ^^^ trigger action button callbacks ^^^

  return (
    <div>
      {notificationRecipientsDataSettings && (
        <DynamicJsonForm
          title={"Email Notifications List"}
          initialJsonData={notificationRecipientsDataSettings}
          callback={updateNotificationRecipientsSettings}
          allowAddField={false}
          largeTextBoxKeys={["settings_emails {list}"]}
        />
      )}
      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <pre>
          {notificationRecipientsDataSNS &&
            JSON.stringify(notificationRecipientsDataSNS, null, 2)}
        </pre>
      </div>
      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StandardActionButton callback={updateNotificationRecipientsFromSettings} text={"Update Notification Recipients"} />
      </div>
    </div>
  );
}

export default NotificationRecipientsPage;
