import React, { useState, useRef, useCallback } from "react";
import { Button, Form, Row, Card } from "react-bootstrap";

export function SchemasForm({ onFormButtonClick }) {
  const formRef = useRef(null);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    onFormButtonClick(e, formRef);
  });

  return (
    <Row style={{ paddingTop: "20px" }} className="justify-content-center">
      <Card
        style={{ width: "60vw", paddingBottom: "20px" }}
        className="bg-dark text-white"
      >
        <Card.Body>
          <h3 style={{ textAlign: "center" }}>Create New Schema</h3>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group
              style={{ paddingTop: "10px" }}
              controlId="booleanField"
              as={Row}
            >
              <div>
                <Form.Check
                  type="checkbox"
                  label="Active"
                  name="active"
                  defaultChecked
                  inline
                />
                <Form.Check
                  type="checkbox"
                  label="Run Crawler"
                  name="run_crawler"
                  defaultChecked
                  inline
                />
              </div>
            </Form.Group>
            <Form.Group style={{ paddingTop: "5px" }} controlId="requiredField">
              <Form.Label>Schema Name:</Form.Label>
              <Form.Control type="text" name="schema_name" required />
            </Form.Group>
            <Form.Group style={{ paddingTop: "5px" }} controlId="accessField">
              <Form.Label>Access Level:</Form.Label>
              <Form.Control as="select" name="access_level">
                <option value="standard">standard</option>
                <option value="secure">secure</option>
              </Form.Control>
            </Form.Group>
            <Form.Group style={{ paddingTop: "5px" }} controlId="optionalField">
              <Form.Label>Schedule (optional):</Form.Label>
              <Form.Control type="text" name="schedule" />
            </Form.Group>
            <div style={{ paddingTop: "10px" }} className="text-center">
              <Button className="justify-content-center" type="submit">
                Create Schema
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
}
