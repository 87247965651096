import { Routes, Route } from "react-router-dom";
import { useState } from "react";

import React from "react";
import "./index.css";
import SchemasPage from "./pages/Schemas";
import TablesPage from "./pages/Tables";
import PipelineExecutionsPage from "./pages/PipelineExecutions";
import Header from "./components/Header";
import AccessPage from "./pages/Access";
import SecretsPage from "./pages/Secrets";
import NotificationRecipientsPage from "./pages/NotificationRecipients";
import DataqualityPage from "./pages/Dataquality";
import LoginPage from "./pages/Login";
import IntegrationGroupsPage from "./pages/IntegrationGroups";

import withAuth from "./components/WithAuth";
import { Messages } from "./components/Messages";
import ErrorBoundary from "./components/ErrorBoundary";
import IntegrationsPage from "./pages/Integrations";
import IntegrationPipelineExecutionsPage from "./pages/IntegrationPipelineExecutions";
import DataqualityEvaluationsPage from "./pages/DataqualityResults";


//https://www.youtube.com/watch?v=puaydMvngj4&t=2s
//https://www.youtube.com/watch?v=nI8PYZNFtac

//add home page
const ProtectedSchemasPage = withAuth(SchemasPage);
const ProtectedTablesPage = withAuth(TablesPage);
const ProtectedPipelineExecutionsPage = withAuth(PipelineExecutionsPage);
const ProtectedAccessPage = withAuth(AccessPage);
const ProtectedSecretsPage = withAuth(SecretsPage);
const ProtectedNotificationRecipientsPage = withAuth(
  NotificationRecipientsPage,
);
const ProtectedDataqualityPage = withAuth(DataqualityPage);
const ProtectedDataqualityEvaluationsPage = withAuth(DataqualityEvaluationsPage);
const ProtectedIntegrationGroupsPage = withAuth(IntegrationGroupsPage);
const ProtectedIntegrationsPage = withAuth(IntegrationsPage);
const ProtectedIntegrationPipelineExecutionsPage = withAuth(IntegrationPipelineExecutionsPage);

const App = () => {
  const [userInfo, setUserInfo] = useState({});
  const [successMessages, setSuccessMessages] = useState([]);
  const [failureMessages, setFailureMessages] = useState([]);

  return (
    <>
      <Header setUserInfo={setUserInfo} userInfo={userInfo} />
        <Messages
          successMessages={successMessages}
          failureMessages={failureMessages}
        />

        <ErrorBoundary>
          <Routes>
            <Route
              path="/login"
              element={<LoginPage setUserInfo={setUserInfo} />}
            />
            
            <Route
              path="/schemas"
              element={
                <ProtectedSchemasPage
                  userInfo={userInfo}
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/tables"
              element={
                <ProtectedTablesPage
                  userInfo={userInfo}
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/pipelineexecutions"
              element={
                <ProtectedPipelineExecutionsPage 
                  userInfo={userInfo} 
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/integrationgroups"
              element={
                <ProtectedIntegrationGroupsPage
                  userInfo={userInfo}
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/integrations"
              element={
                <ProtectedIntegrationsPage
                  userInfo={userInfo}
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/integrationpipelineexecutions"
              element={
                <ProtectedIntegrationPipelineExecutionsPage
                  userInfo={userInfo}
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/dataquality"
                element={
                <ProtectedDataqualityPage 
                  userInfo={userInfo} 
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/dataqualityresults"
                element={
                <ProtectedDataqualityEvaluationsPage
                  userInfo={userInfo} 
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/secrets"
              element={
                <ProtectedSecretsPage
                  userInfo={userInfo}
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/settings/access"
              element={
                <ProtectedAccessPage 
                  userInfo={userInfo} 
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
            <Route
              path="/settings/notifications"
              element={
                <ProtectedNotificationRecipientsPage 
                  userInfo={userInfo} 
                  setFailureMessages={setFailureMessages}
                  setSuccessMessages={setSuccessMessages}
                />
              }
            />
          </Routes>
        </ErrorBoundary>
    </>
  );
};

export default App;
