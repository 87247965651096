import React, { useState } from "react";

const TextInput = ({ title, callback }) => {
  const [name, setName] = useState("eda-");
  const [text, setText] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("in submit");

    callback({ name, text });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="nameInput">Secret Name:</label>
        <input
          type="text"
          id="nameInput"
          className="form-control"
          value={name}
          onChange={handleNameChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="textarea">{title}</label>
        <textarea
          id="textarea"
          className="form-control"
          rows="5"
          value={text}
          onChange={handleTextChange}
        />
      </div>
      <div className="d-flex justify-content-center">
        <button
          style={{
            marginTop: "10px",
            border: "1px solid #fbfbfb",
            backgroundColor: "transparent",
            color: "#fbfbfb",
          }}
          type="submit"
          className="btn btn-primary"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default TextInput;
