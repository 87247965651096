import React, { useState, useRef } from "react";
import { Card, Form, Button, Row } from "react-bootstrap";

const CsvReader = ({ inputMap, callback }) => {
  const formRef = useRef(null);
  const [schemaName, setSchemaName] = useState(Object.keys(inputMap)[0]);
  const [tableName, setTableName] = useState(
    inputMap[Object.keys(inputMap)[0]][0],
  );
  const [csvData, setCsvData] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event.target.result;
      setCsvData(fileContent);
    };

    reader.readAsText(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const data = {
      schema_name: formData.get("schemaName"),
      table_name: formData.get("tableName"),
      csv: csvData,
    };
    callback(data);
  };

  return (
    <Row style={{ paddingTop: "20px" }} className="justify-content-center">
      <Card
        style={{ width: "60vw", paddingBottom: "20px" }}
        className="bg-dark text-white"
      >
        <Card.Body>
          <h3 style={{ textAlign: "center" }}>Create Table From CSV</h3>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Schema Name</Form.Label>
              <Form.Control
                as="select"
                value={schemaName}
                onChange={(event) => setSchemaName(event.target.value)}
                name="schemaName"
                style={{ marginRight: "1vw", width: "55vw" }}
              >
                {Object.keys(inputMap).map((schema, index) => (
                  <option key={index} value={schema}>
                    {schema}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Table Name</Form.Label>
              <Form.Control
                as="select"
                value={tableName}
                onChange={(event) => setTableName(event.target.value)}
                name="tableName"
                style={{ marginRight: "1vw", width: "55vw" }}
              >
                {inputMap[schemaName].map((table, index) => (
                  <option key={index} value={table}>
                    {table}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>CSV File:</Form.Label>
              <Form.Control
                style={{ marginRight: "1vw", width: "55vw" }}
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
            </Form.Group>
            <div style={{ paddingTop: "10px", textAlign: "center" }}>
              <Button type="submit" variant="secondary" size="lg">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CsvReader;
