import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

const DismissibleAlert = ({ variant, index, message }) => {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <Alert
          key={`${variant}_${index}`}
          variant={variant}
          onClose={() => setShow(false)}
          dismissible
        >
          {message}
        </Alert>
      )}
    </>
  );
};

export const Messages = ({ successMessages, failureMessages }) => {
  return (
    <>
      {successMessages.map((message, index) => (
        <DismissibleAlert variant={"success"} index={index} message={message} />
      ))}
      {failureMessages.map((message, index) => (
        <DismissibleAlert variant={"danger"} index={index} message={message} />
      ))}
    </>
  );
};
