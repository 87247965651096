import { useEffect, useState } from "react";
import { SearchBar } from "../components/forms/SearchBar";
import {
  edaPost,
  edaPut,
  edaDelete,
  handleAPIActionCall,
} from "../utils/api/callapi";
import DynamicJsonForm from "../components/forms/DynamicJsonForm";
import { Accordion, Button, Alert } from "react-bootstrap";
import TextInput from "../components/forms/TextInputSecrets";

function SecretsPage({ userInfo, setFailureMessages, setSuccessMessages }) {
  const [secret, setSecret] = useState({});
  const [secretName, setSecretName] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [createReplaceFromForm, setCreateReplaceFromForm] = useState(true);
  const CLIENT_ACCOUNT_ID = userInfo.client_account_id;

  // --- api routes ---
  const GET_API_ROUTE = "secretsmanager";
  const WRITE_API_ROUTE = "secretsmanager/write";
  const DELETE_API_ROUTE = "secretsmanager/delete";
  // ^^^ api routes ^^^

  useEffect(() => {
    setFailureMessages((prev) => []);
    setSuccessMessages((prev) => []);
  }, []); //clear any messages from other components on first load of this component

  const getSecret = (secretName) => {
    edaPost(userInfo.axiosInstance, GET_API_ROUTE, {
      client_account_id: CLIENT_ACCOUNT_ID,
      action: "get",
      secret_name: secretName,
    })
      .then((response) => {
        setSecret((prevData) => JSON.parse(response.data.secret));
        setSecretName((prevData) => secretName);
      })
      .catch((error) => {
        setSecret((prevData) => {});
        setFailureMessages((prevFailureMessages) => [error.message]);
      });
  };

  const createOrReplaceSecret = (jsonFormData) => {
    const name = jsonFormData.secret_name || secretName;
    delete jsonFormData.secret_name;

    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, WRITE_API_ROUTE, {
        client_account_id: CLIENT_ACCOUNT_ID,
        action: "create",
        secret_name: name,
        secret: jsonFormData,
      }),
      setSuccessMessages,
      setFailureMessages,
    );
  };

  const createOrReplaceSecretFromText = (jsonFormData) => {
    console.log(jsonFormData);
    const name = jsonFormData.name;
    const jsonSecret = JSON.parse(jsonFormData.text);

    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, WRITE_API_ROUTE, {
        client_account_id: CLIENT_ACCOUNT_ID,
        action: "create",
        secret_name: name,
        secret: jsonSecret,
      }),
      setSuccessMessages,
      setFailureMessages,
    );
  };

  const deleteSecret = () => {
    console.log(secretName);

    handleAPIActionCall(
      edaDelete(userInfo.axiosInstance, DELETE_API_ROUTE, {
        client_account_id: CLIENT_ACCOUNT_ID,
        action: "delete",
        secret_name: secretName,
        force: true, //Danger this should be changed before production
      }),
      setSuccessMessages,
      setFailureMessages,
    );
  };

  return (
    <div>
      <SearchBar callback={getSecret} defaultText={"Get secret..."} />
      <pre>
        {secret &&
          Object.keys(secret).length > 0 &&
          JSON.stringify(secret, null, 2)}
      </pre>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        {secret && Object.keys(secret).length > 0 && (
          <Button
            variant="primary"
            onClick={() => setShowEdit((prevData) => !prevData)}
          >
            Edit
          </Button>
        )}
        {secret && Object.keys(secret).length > 0 && (
          <Button
            style={{
              border: "2px solid darkred",
              backgroundColor: "rgb(80, 7, 7)",
              color: "#fbfbfb",
            }}
            variant="primary"
            onClick={deleteSecret}
          >
            Delete
          </Button>
        )}
      </div>
      {showEdit && (
        <DynamicJsonForm
          title={"Edit Secret"}
          initialJsonData={secret}
          callback={createOrReplaceSecret}
        />
      )}

      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Create or Replace Secret</Accordion.Header>
            <Accordion.Body>
              <div
                style={{
                  paddingTop: "10px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    border: " 2px solid green",
                    backgroundColor: "rgba(0, 128, 0, 0.387)",
                    color: "#fbfbfb",
                  }}
                  variant="primary"
                  onClick={() => setCreateReplaceFromForm((prevData) => true)}
                >
                  Form
                </Button>
                <Button
                  style={{
                    marginLeft: "10px",
                    border: "1px solid #fbfbfb",
                    backgroundColor: "transparent",
                    color: "#fbfbfb",
                  }}
                  variant="primary"
                  onClick={() => setCreateReplaceFromForm((prevData) => false)}
                >
                  Raw Text
                </Button>
              </div>
              {createReplaceFromForm && (
                <DynamicJsonForm
                  title={"Create or Replace Secret"}
                  initialJsonData={{ secret_name: "eda-" }}
                  callback={createOrReplaceSecret}
                />
              )}
              {!createReplaceFromForm && (
                <TextInput
                  title={"Secret (must be valid json)"}
                  callback={createOrReplaceSecretFromText}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default SecretsPage;
