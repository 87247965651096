import React, { useState, useRef } from "react";
import { Card, Form, Button, Row } from "react-bootstrap";

const CreateFromCsv = ({ title, callback }) => {
  const formRef = useRef(null);
  const [csvData, setCsvData] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event.target.result;
      setCsvData(fileContent);
    };

    reader.readAsText(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //const formData = new FormData(formRef.current);
    const data = {
      csv: csvData,
    };
    callback(data);
  };

  return (
    <Row style={{ paddingTop: "20px" }} className="justify-content-center">
      <Card
        style={{ width: "60vw", paddingBottom: "20px" }}
        className="bg-dark text-white"
      >
        <Card.Body>
          <h3 style={{ textAlign: "center" }}>{title}</h3>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>CSV File:</Form.Label>
              <Form.Control
                style={{ marginRight: "1vw", width: "55vw" }}
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
            </Form.Group>
            <div style={{ paddingTop: "10px", textAlign: "center" }}>
              <Button type="submit" variant="secondary" size="lg">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default CreateFromCsv;
