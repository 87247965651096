import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect, useMemo } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { Accordion, Button, Alert } from "react-bootstrap";

import { SchemasForm } from "../components/forms/SchemasForm";
import UpdateValuesForm from "../components/forms/UpdateValuesForm";
import CreateFromCsv from "../components/forms/CreateFromCsv";
import DynamicJsonForm from "../components/forms/DynamicJsonForm";
import SchemaTree from "../components/charts/SchemaTree";
import {
  edaPost,
  edaPut,
  edaDelete,
  handleAPIActionCall,
} from "../utils/api/callapi";
import {
  fetchAggridData,
  formatDataAggrid,
  generateAggridColumnDefs,
  orderKeysAggrid,
} from "../utils/aggrid-helpers";
import { StandardActionButton } from "../components/StandardActionButton";

function SchemasPage({ userInfo, setFailureMessages, setSuccessMessages }) {
  const gridRef = useRef();
  const changes = useRef({});
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [showJsonEditorEdit, setShowJsonEditorEdit] = useState(false);
  const [schemaTreeData, setSchemaTreeData] = useState(false);
  const [gridRefreshRequired, setGridRefreshRequired] = useState(false);
  const CLIENT_ACCOUNT_ID = userInfo.client_account_id;

  // --- aggrid settings ---
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    editable: true,
    resizable: true,
  }));

  const PREFERRED_COLUMN_ORDER = [
    "active",
    "schema_name",
    "access_level",
    "schedule",
    "dependent_schemas",
    "data_owners",
    "run_crawler",
    "connection_secret_name",
  ];
  const BOOLEAN_COLUMNS = ["active", "run_crawler"];
  // ^^^ aggrid settings ^^^

  // --- api routes ---
  const READ_API_ROUTE = "schemas";
  const TRIGGER_API_ROUTE = "etlpipeline/trigger";
  const CREATE_API_ROUTE = "schemas/create";
  const UPDATE_API_ROUTE = "schemas/update";
  const DELETE_API_ROUTE = "schemas/delete";
  const REFRESH_DATABASE_API_ROUTE = "schemas/refresh";
  const UPDATE_SCHEDULES_API_ROUTE = "schemas/setupdateschedule";
  const UPDATE_ACCESS_API_ROUTE = "schemas/setaccesslevel";
  const CREATE_FROM_CSV_API_ROUTE = "schemas/create/fromcsv";
  const GET_D3_JSON_API_ROUTE = "schemas/orchestration";
  // ^^^ api routes ^^^

  // --- on mount or account change ---
  useEffect(() => {
    setFailureMessages((prev) => []);
    setSuccessMessages((prev) => []);
  }, []); //clear any messages from other components on first load of this component

  useEffect(() => {
    reloadData();
  }, [userInfo]); // populate grid data on first load of this component or change in userInfo
  // ^^^ on mount or account change ^^^

  // --- populating grid with data and setting grid properties ---
  const reloadData = async () => {
    const postData = {
      client_account_id: userInfo.client_account_id,
    };

    try {
      const rowData = await fetchAggridData(
        userInfo.axiosInstance,
        READ_API_ROUTE,
        postData,
        "schemas",
      );
      const { formattedData, allKeys } = formatDataAggrid(rowData);
      const orderedKeys = orderKeysAggrid(PREFERRED_COLUMN_ORDER, allKeys);
      const columnDefs = generateAggridColumnDefs(orderedKeys, BOOLEAN_COLUMNS);

      setRowData((prev) => formattedData);
      setColumnDefs((prev) => columnDefs);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // ^^^ populating grid with data and setting grid properties ^^^

  // --- refreshing grid on certain events ---
  const refreshGrid = () => {
    changes.current = {};
    setHasChanges((prevData) => false);
    setGridRefreshRequired(true);
  };

  useEffect(() => {
    if (gridRefreshRequired) {
      reloadData();
      gridRef.current.api.refreshCells();
      setGridRefreshRequired(false);
    }
  }, [gridRefreshRequired]); // reload data and redraw grid when gridRefreshRequired changes to true
  // ^^^ refreshing grid on certain events ^^^

  // --- editing grid cells directly in grid ---
  const onCellValueChanged = (event) => {
    const { data, rowIndex } = event;

    if (!changes.current.hasOwnProperty(rowIndex)) {
      let originalItem = { ...data };
      originalItem[event.colDef.columnName] = event.oldValue;
      changes.current[rowIndex] = {};
      changes.current[rowIndex]["original"] = originalItem;
    }

    changes.current[rowIndex]["changed"] = event.data;
    setHasChanges((prevData) => true);
  }; // keeps track of unsubmitted changes made directly to grid. Maybe we should remove editability of grid?

  const submitChanges = () => {
    for (const idx in changes.current) {
      console.log(idx);
      console.log(changes.current[idx]);
      let schema = changes.current[idx]["changed"];
      if (schema["schedule"] === "") {
        schema["schedule"] = null;
      }
      console.log("schema", schema);
      var putData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema: schema,
      };
      // this likely does not run in parallel because of how I wrote this. look into that later
      handleAPIActionCall(
        edaPut(userInfo.axiosInstance, CREATE_API_ROUTE, putData),
        setSuccessMessages,
        setFailureMessages,
      );
    }
    refreshGrid();
  }; // iterates through items in changes object an puts to api, updating them in dynamodb

  const undoChanges = () => {
    setRowData((originalRows) => {
      const newRows = [...originalRows];
      for (const idx in changes.current) {
        newRows[idx] = Object.fromEntries(
          Object.entries(changes.current[idx]["original"]),
        );
      }
      return newRows;
    });
    refreshGrid();
  }; // clears changes object and reverts grid to original state
  // ^^^ editing grid cells directly in grid ^^^

  // --- edit schema item in form ---
  const handleToggleJsonFormEditor = () => {
    try {
      const _ = gridRef.current.api.getSelectedNodes()[0].data;
      setShowJsonEditorEdit((prev) => !prev);
    } catch {
      console.log("nothing selected");
    }
  };

  const editSchemaFromJsonForm = (formData) => {
    const formKeys = Object.keys(formData);
    formData["client_account_id"] = CLIENT_ACCOUNT_ID;
    BOOLEAN_COLUMNS.map((col) => {
      formData[col] = formKeys.includes(col);
    });
    console.log(formData);
    const putData = {
      client_account_id: CLIENT_ACCOUNT_ID,
      schema: formData,
    };
    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, CREATE_API_ROUTE, putData),
      setSuccessMessages,
      setFailureMessages,
    );
    setShowJsonEditorEdit(false);
    refreshGrid();
  };
  // ^^^ edit schema item in form ^^^

  const deleteSchema = () => {
    console.log("in delete");
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    console.log(selectedNodes);
    selectedNodes.forEach((node) => {
      console.log(node.data);
      var deleteData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: node.data.schema_name,
      };
      console.log(deleteData);

      handleAPIActionCall(
        edaDelete(userInfo.axiosInstance, DELETE_API_ROUTE, deleteData),
        setSuccessMessages,
        setFailureMessages,
      );
      refreshGrid();
    });
  }; // deletes selected schema items

  // --- trigger action button callbacks ---
  const triggerPipeline = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    selectedNodes.map((node) => {
      console.log(node.data.schema_name);
      handleAPIActionCall(
        edaPost(userInfo.axiosInstance, TRIGGER_API_ROUTE, {
          client_account_id: CLIENT_ACCOUNT_ID,
          schema_name: node.data.schema_name,
        }),
        setSuccessMessages,
        setFailureMessages,
      );
    });
  }; // trigger etl pipeline executions for selected schemas

  const refreshSchema = () => {
    console.log("in refresh schemas");
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    selectedNodes.map((node) => {
      console.log(node.data.schema_name);
      handleAPIActionCall(
        edaPost(userInfo.axiosInstance, REFRESH_DATABASE_API_ROUTE, {
          client_account_id: CLIENT_ACCOUNT_ID,
          schema_name: node.data.schema_name,
        }),
        setSuccessMessages,
        setFailureMessages,
      );
    });
  }; // trigger crawler run for selected schemas

  const updateSchedules = () => {
    console.log("in update schedules");
    handleAPIActionCall(
      edaPost(userInfo.axiosInstance, UPDATE_SCHEDULES_API_ROUTE, {
        action: "update_schedule_from_schema_table",
        client_account_id: CLIENT_ACCOUNT_ID,
      }),
      setSuccessMessages,
      setFailureMessages,
    );
    refreshGrid();
  }; // update eventbridge rules to match schedules set in schedule column for each schema

  const updateAccess = () => {
    console.log("in update access");
    handleAPIActionCall(
      edaPost(userInfo.axiosInstance, UPDATE_ACCESS_API_ROUTE, {
        action: "update_access_from_schema_table",
        client_account_id: CLIENT_ACCOUNT_ID,
      }),
      setSuccessMessages,
      setFailureMessages,
    );
  }; // applies lf tagging to schemas based on what is in this table

  const getSchemaTree = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    if (selectedNodes) {
      const node = selectedNodes[0];
      const postData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        action: "get_d3_json",
        schema_name: node.data.schema_name,
        from_root: true,
      };

      edaPost(userInfo.axiosInstance, GET_D3_JSON_API_ROUTE, postData)
        .then((response) => {
          console.log(response);
          const treeData = JSON.parse(response.data.tree);
          setSchemaTreeData((prevData) => treeData);
        })
        .catch((error) => {
          setFailureMessages((prevMessages) => [
            ...prevMessages,
            error.response.data.message,
          ]);
        });
    }
  }; // gets schema tree from api and draws using d3. ugly. just having it here so real ui person can pick up later

  // ^^^ trigger action button callbacks ^^^

  // --- accordion section callbacks ---
  const createSchema = (e, formRef) => {
    console.log("Button in SchemasForm was clicked!");
    const formData = new FormData(formRef.current);
    const jsonFormData = {};

    formData.forEach((value, key) => {
      console.log(key, value);
      if (BOOLEAN_COLUMNS.includes(key)) {
        jsonFormData[key] = value === "on" ? true : false;
      } else if (key === "schedule" && value === "") {
        jsonFormData[key] = null;
      } else {
        jsonFormData[key] = value;
      }
    });

    BOOLEAN_COLUMNS.forEach((key) => {
      if (!jsonFormData.hasOwnProperty([key])) {
        jsonFormData[[key]] = false;
      }
    });

    console.log(jsonFormData);
    var putData = {
      client_account_id: CLIENT_ACCOUNT_ID,
      schema: jsonFormData,
    };
    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, CREATE_API_ROUTE, putData),
      setSuccessMessages,
      setFailureMessages,
    );
    refreshGrid();
  }; // create a new schema from little schema form in accordion

  const createItemsFromCsv = (formData) => {
    formData["client_account_id"] = CLIENT_ACCOUNT_ID;
    console.log(formData);
    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, CREATE_FROM_CSV_API_ROUTE, formData),
      setSuccessMessages,
      setFailureMessages,
    );
    refreshGrid();
  }; // create new schema items by uploading a csv

  const bulkUpdateValues = (e, formRef) => {
    console.log("Button in UpdateValuesForm was clicked!");

    const formData = new FormData(formRef.current);

    const columnName = formData.get("columnName");
    const value = formData.get("value");

    console.log(columnName, value);

    if (BOOLEAN_COLUMNS.includes(columnName)) {
      value =
        value.toLocaleLowerCase() === "on" ||
        value.toLocaleLowerCase() === "true"
          ? true
          : false;
    } else if (columnName === "schedule" && value === "") {
      value = null;
    }

    const selectedNodes = gridRef.current.api.getSelectedNodes();

    selectedNodes.map((node) => {
      console.log("update node data", node.data);
      var putData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: node.data.schema_name,
        update_values: { [columnName]: value },
      };
      console.log(putData);
      handleAPIActionCall(
        edaPut(userInfo.axiosInstance, UPDATE_API_ROUTE, putData),
        setSuccessMessages,
        setFailureMessages,
      );
    });
    refreshGrid();
  }; // update the volume of a column across many row items
  // ^^^ accordion section callbacks ^^^

  return (
    <div
      className="ag-theme-alpine-dark"
      style={{ height: 600, padding: "20px" }}
    >
      {hasChanges && <Alert variant="warning">Unsubmitted changes</Alert>}
      <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "5px" }}
      >
        <Button variant="quaternary" onClick={reloadData}>
          Refresh
        </Button>
      </div>
      <AgGridReact
        ref={gridRef}
        onCellValueChanged={onCellValueChanged}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection="multiple"
        animateRows={true}
        defaultColDef={defaultColDef}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        <Button
          variant="secondary"
          onClick={() => gridRef.current?.api.exportDataAsCsv()}
        >
          Export as CSV
        </Button>
        <div>
          <Button variant="quaternary" onClick={handleToggleJsonFormEditor}>
            Edit Selected
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            variant="tertiary"
            onClick={deleteSchema}
          >
            Delete Selected
          </Button>
        </div>
      </div>
      {showJsonEditorEdit &&
        gridRef.current.api.getSelectedNodes().length > 0 && (
          <DynamicJsonForm
            title={`Edit ${
              gridRef.current.api.getSelectedNodes()[0].data.schema_name
            }`}
            initialJsonData={gridRef.current.api.getSelectedNodes()[0].data}
            callback={editSchemaFromJsonForm}
          />
        )}

      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!showJsonEditorEdit && (
          <StandardActionButton
            callback={submitChanges}
            text={"Submit Changes"}
          />
        )}
        <StandardActionButton
          callback={triggerPipeline}
          text={"Trigger Pipeline"}
        />
        <StandardActionButton
          callback={updateSchedules}
          text={"Update Schedules"}
        />
      </div>
      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StandardActionButton callback={undoChanges} text={"Undo Changes"} />
        <StandardActionButton
          callback={refreshSchema}
          text={"Refresh Schema"}
        />
        <StandardActionButton
          callback={updateAccess}
          text={"Update Access Levels"}
        />
      </div>
      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StandardActionButton
          callback={getSchemaTree}
          text={"Show Schema Tree"}
        />
      </div>

      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Create Schema</Accordion.Header>
            <Accordion.Body>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SchemasForm onFormButtonClick={createSchema} />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Bulk Update Selected Column Values
            </Accordion.Header>
            <Accordion.Body>
              <UpdateValuesForm
                onFormButtonClick={bulkUpdateValues}
                columnNames={columnDefs.map((column) => column.columnName)}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Create Items From CSV</Accordion.Header>
            <Accordion.Body>
              <CreateFromCsv
                title={"Create Schema Items From CSV"}
                callback={createItemsFromCsv}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      {schemaTreeData && (
        <div>
          <SchemaTree data={schemaTreeData} />
        </div>
      )}
    </div>
  );
}

export default SchemasPage;
