import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
const SchemaTree = ({ data }) => {
  const chartRef = useRef();

  useEffect(() => {
    const width = 1600;
    const height = 1600;

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("background-color", "white"); // Set background color

    const treeLayout = d3.tree().size([width - 100, height - 40]);
    const root = d3.hierarchy(data);

    treeLayout(root);

    const links = root.links();
    svg
      .selectAll(".link")
      .data(links)
      .enter()
      .append("line")
      .attr("class", "link")
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y)
      .style("stroke", "#ccc"); // Set link color

    const nodes = root.descendants();
    const node = svg
      .selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.x},${d.y})`);

    node.append("circle").attr("r", 5).style("fill", "lightblue"); // Set node color

    node
      .append("text")
      .attr("dy", -10)
      .attr("text-anchor", "middle")
      .style("font-size", "6px")
      .style("fill", "white") // Set text color
      .text((d) => d.data.name)
      .on("mouseover", function () {
        d3.select(this)
          .style("font-size", "24px")
          .style("background-color", "rgba(255, 255, 255, 0.8)");
      })
      .on("mouseout", function () {
        d3.select(this)
          .style("font-size", "6px")
          .style("background-color", "transparent");
      });
  }, [data]);

  return <div ref={chartRef}></div>;
};

export default SchemaTree;
