import React, { useRef, useState } from "react";
import { Button, Form, Row, Card } from "react-bootstrap";

//Update this so it does on submit not on change

const UpdateValuesForm = ({ onFormButtonClick, columnNames }) => {
  const formRef = useRef(null);
  const [columnName, setColumnName] = useState("");
  const [value, setValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onFormButtonClick(e, formRef);
  };

  return (
    <Row style={{ paddingTop: "20px" }} className="justify-content-center">
      <Card style={{ width: "60vw" }} className="bg-dark text-white">
        <Card.Body>
          <h3 style={{ textAlign: "center" }}>Bulk Update Column Values</h3>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "5px",
              }}
            >
              <Form.Group className="mr-3">
                <Form.Label>Column Name</Form.Label>
                <Form.Control
                  as="select"
                  value={columnName}
                  onChange={(e) => setColumnName(e.target.value)}
                  name="columnName"
                  style={{ marginRight: "1vw", width: "50vw" }}
                >
                  {columnNames.map((column, index) => (
                    <option key={index} value={column}>
                      {column}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mr-3">
                <Form.Label>Value</Form.Label>
                <Form.Control
                  type="text"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  name="value"
                  style={{ marginRight: "1vw", width: "50vw" }}
                />
              </Form.Group>
              <div style={{ paddingTop: "10px" }}>
                <Button type="submit" variant="secondary" size="lg">
                  Update Selected Items
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default UpdateValuesForm;
