// handle errors and in progress better when those are clicked on

import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect, useMemo } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import {
  edaPost,
  handleAPIActionCall
} from "../utils/api/callapi";
import {
  fetchAggridData,
  formatDataAggrid,
  generateAggridColumnDefs,
  orderKeysAggrid,
} from "../utils/aggrid-helpers";

import { Button } from "react-bootstrap";

import DQResultsTable from "../components/tables/DQResultsTable";

function DataqualityEvaluationsPage({ userInfo, setSuccessMessages, setFailureMessages }) {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [gridRefreshRequired, setGridRefreshRequired] = useState(false);
  const CLIENT_ACCOUNT_ID = userInfo.client_account_id;
  

  // --- api routes ---
  const READ_API_ROUTE = "/dataquality/results";
  const STOP_API_ROUTE = "dataquality/stop";
  // ^^^ api routes ^^^

  // --- aggrid settings ---
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    editable: false,
    resizable: true,
  }));

  const PREFERRED_COLUMN_ORDER = [
    "full_table_name",
    "start_time",
    "status",
    "score",
    "success_threshold",
    "job_run_id"
  ];
  const BOOLEAN_COLUMNS = [];
  const EXCLUDE_COLUMNS = ["failed_row_sample", "results"];
  // ^^^ aggrid settings ^^^

  useEffect(() => {
    reloadData();
  }, [userInfo]);

  // --- populating grid with data and setting grid properties ---
  const reloadData = async () => {
    const postData = {
      client_account_id: userInfo.client_account_id,
    };

    try {
      const rowData = await fetchAggridData(
        userInfo.axiosInstance,
        READ_API_ROUTE,
        postData,
        "results",
      );
      const { formattedData, allKeys } = formatDataAggrid(rowData, EXCLUDE_COLUMNS);
      const orderedKeys = orderKeysAggrid(PREFERRED_COLUMN_ORDER, allKeys);
      const columnDefs = generateAggridColumnDefs(orderedKeys, BOOLEAN_COLUMNS);

      setRowData((prev) => formattedData);
      setColumnDefs((prev) => columnDefs);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // ^^^ populating grid with data and setting grid properties ^^^

  // --- refreshing grid on certain events ---
  const refreshGrid = () => {
    setGridRefreshRequired(true);
  };

  useEffect(() => {
    if (gridRefreshRequired) {
      reloadData();
      gridRef.current.api.refreshCells();
      setGridRefreshRequired(false);
    }
  }, [gridRefreshRequired]); // reload data and redraw grid when gridRefreshRequired changes to true
  // ^^^ refreshing grid on certain events ^^^


  const onRowSelected = (event) => {
    if (event.node && event.node.isSelected()) {
      setSelectedRowData(event.data);
    }
  };

  const stopExecution = () => {
    console.log("in stop");
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    console.log(selectedNodes);
    selectedNodes.forEach((node) => {
      console.log(node.data);
      var postData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        pipeline_execution_id: node.data.pipeline_execution_id,
      };
      console.log(postData);
      handleAPIActionCall(edaPost(userInfo.axiosInstance, STOP_API_ROUTE, postData),setSuccessMessages, setFailureMessages);
    });
    refreshGrid();
  };
  
  return (
    <div
      className="ag-theme-alpine-dark"
      style={{ height: 600, padding: "20px" }}
    >
      <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "5px" }}
      >
        <Button variant="quaternary" onClick={reloadData}>
          Refresh
        </Button>
      </div>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection="multiple"
        animateRows={true}
        defaultColDef={defaultColDef}
        onRowSelected={onRowSelected}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        <Button variant="secondary" onClick={() => gridRef.current?.api.exportDataAsCsv()}>
          {" "}
          Export CSV{" "}
        </Button>
        <Button variant="tertiary" onClick={stopExecution}>
          Stop Execution
        </Button>
      </div>
      {selectedRowData && (
          <DQResultsTable initialJsonData={selectedRowData} />
      )}
    </div>
  );
}

export default DataqualityEvaluationsPage;
