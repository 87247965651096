import React, { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { Button } from "react-bootstrap";

export const JsonEditor = ({ initialJsonData, callback }) => {
  const [editedJsonData, setEditedJsonData] = useState(initialJsonData);

  useEffect(() => {
    setEditedJsonData(initialJsonData);
  }, [initialJsonData]);

  const handleJsonChange = (newJsonData) => {
    setEditedJsonData(newJsonData);
  };

  const handleButtonClick = () => {
    if (editedJsonData && !editedJsonData.error) {
      console.log(editedJsonData);
      if (callback) {
        callback(editedJsonData);
      }
    }
  };

  return (
    <div
      style={{
        width: "75vw",
        height: "40vw",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {initialJsonData && (
        <JSONInput
          placeholder={initialJsonData}
          theme="dark_vscode_tribute"
          locale={locale}
          height="100%"
          width="100%"
          onChange={(newJsonData) => handleJsonChange(newJsonData)}
        />
      )}
      <Button
        style={{ marginRight: "1vw", width: "15vw", marginTop: "16px" }}
        variant="primary"
        size="lg"
        onClick={handleButtonClick}
      >
        Submit Changes
      </Button>
    </div>
  );
};
