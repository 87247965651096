import { useState, useEffect } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


import {
  edaPost,
  edaPut,
  handleAPIActionCall,
} from "../utils/api/callapi";

import { StandardActionButton } from "../components/StandardActionButton";
import { JsonEditor } from "../components/forms/JsonEditor";



function AccessPage({ userInfo, setFailureMessages, setSuccessMessages }) {
  const [accessData, setAccessData] = useState({});
  const CLIENT_ACCOUNT_ID = userInfo.client_account_id;

  // --- api routes ---
  const READ_API_ROUTE = "account";
  const ACCESS_LEVEL_API_ROUTE = "account/accesslevel";
  // ^^^ api routes ^^^

  // --- on mount or account change ---
  useEffect(() => {
    console.log("in original use effect");
    reloadData();
  }, [userInfo]);
  // ^^^ on mount or account change ^^^

  // --- populate data ---
  const reloadData = async () => {
    console.log("in reload data");
    const postData = {
      client_account_id: userInfo.client_account_id,
      setting: "access_level",
    };

    edaPost(userInfo.axiosInstance, READ_API_ROUTE, postData)
      .then((result) => {
        console.log(result);
        return result.data.settings[0].value;
      })
      .then((data) => {
        setAccessData(prev => data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // ^^^ populate data ^^^

  // --- action button callbacks ---
  const submitData = async (jsonData) => {
    if (jsonData.jsObject) {
      const putData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        setting: "access_level",
        value: jsonData.jsObject,
      };
      console.log(putData);
      handleAPIActionCall(edaPut(userInfo.axiosInstance, ACCESS_LEVEL_API_ROUTE, putData), setSuccessMessages, setFailureMessages);
      await reloadData();
    } else {
      console.log("no changes");
    }
  };

  const updateAccessPolicies = async () => {
    const postData = {
      client_account_id: CLIENT_ACCOUNT_ID,
      action: "update_access_level_policies_from_settings",
    };
    console.log(postData);
    handleAPIActionCall(edaPost(userInfo.axiosInstance, ACCESS_LEVEL_API_ROUTE, postData), setSuccessMessages, setFailureMessages);
    await reloadData();
  };
  // ^^^ action button callbacks ^^^

  return (
    <>
      {accessData && <JsonEditor initialJsonData={accessData} callback={submitData} />}
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StandardActionButton callback={updateAccessPolicies} text={"Update Access Policies"} />
      </div>
    </>
    
  );
}

export default AccessPage;
