import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect, useMemo } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { Accordion, Button, Alert } from "react-bootstrap";

import UpdateValuesForm from "../components/forms/UpdateValuesForm";
import { getChangeSet } from "../utils/common-functions";
import CsvReader from "../components/forms/CsvReader";
import CreateFromCsv from "../components/forms/CreateFromCsv";
import DynamicJsonForm from "../components/forms/DynamicJsonForm";
import { StandardActionButton } from "../components/StandardActionButton";
import {
  edaPost,
  edaPut,
  edaDelete,
  handleAPIActionCall,
} from "../utils/api/callapi";
import {
  fetchAggridData,
  formatDataAggrid,
  generateAggridColumnDefs,
  orderKeysAggrid,
} from "../utils/aggrid-helpers";

function TablesPage({ userInfo, setSuccessMessages, setFailureMessages }) {
  const gridRef = useRef();
  const changes = useRef({});
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  //const [schemasTablesMap, setSchemasTablesMap] = useState({});
  const [csvSchemasTablesMap, setCsvSchemasTablesMap] = useState({});
  const [showJsonEditorEdit, setShowJsonEditorEdit] = useState(false);
  const [showJsonEditorCreate, setShowJsonEditorCreate] = useState(false);
  const [gridRefreshRequired, setGridRefreshRequired] = useState(false);
  const CLIENT_ACCOUNT_ID = userInfo.client_account_id;

  // --- aggrid settings ---

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    editable: true,
    resizable: true,
  }));

  const defaultTableItem = useMemo(() => ({
    active: true,
    schema_name: "",
    table_name: "",
    job_name: "",
  }));

  const PREFERRED_COLUMN_ORDER = [
    "active",
    "schema_name",
    "table_name",
    "load_order",
    "job_name",
    "last_load_status",
    "last_success_time",
    "last_job_run_id",
    "last_pipeline_execution_id",
    "partition_column",
    "connection_secret_name",
    "query",
  ];
  const BOOLEAN_COLUMNS = ["active"];

  // ^^^ aggrid settings ^^^

  // --- api routes ---
  const TRIGGER_API_ROUTE = "etlpipeline/trigger";
  const READ_API_ROUTE = "tables";
  const CREATE_API_ROUTE = "tables/create";
  const UPDATE_API_ROUTE = "tables/update";
  const DELETE_API_ROUTE = "tables/delete";
  const UPLOAD_CSV_API_ROUTE = "tables/uploadcsv";
  const CREATE_FROM_CSV_API_ROUTE = "tables/create/fromcsv";

  // ^^^ api routes ^^^

  // --- on mount or account change ---
  useEffect(() => {
    setFailureMessages((prev) => []);
    setSuccessMessages((prev) => []);
  }, []); //clear any messages from other components on first load of this component

  useEffect(() => {
    reloadData();
  }, [userInfo]); // populate grid data on first load of this component or change in userInfo
  // ^^^ on mount or account change ^^^

  // --- populating grid with data and setting grid properties ---
  const groupTablesBySchema = async (tableItems) => {
    const schemasTablesMap = {};
    tableItems.forEach((item) => {
      if (!(item.schema_name in schemasTablesMap)) {
        schemasTablesMap[item.schema_name] = [];
      }
      schemasTablesMap[item.schema_name].push(item.table_name);
    });
    return schemasTablesMap;
  }; // not used right now but I feel like this is something that we will want. Was doing somehting with it but forgot what

  const getCsvCuratedItems = async (tableItems) => {
    const csvCuratedItemsMap = {};
    tableItems.forEach((item) => {
      if (item.job_name === "csv-curated") {
        if (!(item.schema_name in csvCuratedItemsMap)) {
          csvCuratedItemsMap[item.schema_name] = [];
        }
        csvCuratedItemsMap[item.schema_name].push(item.table_name);
      }
    });
    return csvCuratedItemsMap;
  }; // used by accordion to display tables that can be created from csv

  const reloadData = async () => {
    const postData = {
      client_account_id: userInfo.client_account_id,
    };

    try {
      const rowData = await fetchAggridData(
        userInfo.axiosInstance,
        READ_API_ROUTE,
        postData,
        "tables",
      );
      const { formattedData, allKeys } = formatDataAggrid(rowData);
      const orderedKeys = orderKeysAggrid(PREFERRED_COLUMN_ORDER, allKeys);
      const columnDefs = generateAggridColumnDefs(orderedKeys, BOOLEAN_COLUMNS);

      //const schemasTablesMap = groupTablesBySchema(formattedData); //not actually used anywhere may rethink
      const csvCuratedItemsMap = await getCsvCuratedItems(formattedData);

      setRowData((prev) => formattedData);
      setColumnDefs((prev) => columnDefs);
      //setSchemasTablesMap((prevData) => schemasTablesMap);
      setCsvSchemasTablesMap((prevData) => csvCuratedItemsMap);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // ^^^ populating grid with data and setting grid properties ^^^

  // refreshing grid on certain events
  const refreshGrid = () => {
    changes.current = {};
    setHasChanges((prevData) => false);
    setGridRefreshRequired(true);
  };

  useEffect(() => {
    if (gridRefreshRequired) {
      reloadData();
      gridRef.current.api.refreshCells();
      setGridRefreshRequired(false);
    }
  }, [gridRefreshRequired]); // reload data and redraw grid when gridRefreshRequired changes to true
  // ^ refreshing grid on certain events ^

  // --- editing grid cells directly in grid ---
  const onCellValueChanged = (event) => {
    const { data, rowIndex } = event;

    if (!changes.current.hasOwnProperty(rowIndex)) {
      let originalItem = { ...data };
      originalItem[event.colDef.columnName] = event.oldValue;
      changes.current[rowIndex] = {};
      changes.current[rowIndex]["original"] = originalItem;
    }

    changes.current[rowIndex]["changed"] = event.data;
    setHasChanges((prevData) => true);
  }; // keeps track of unsubmitted changes made directly to grid. Maybe we should remove editability of grid?

  const submitChanges = () => {
    console.log("in submit changes");
    for (const idx in changes.current) {
      console.log(idx);
      console.log(changes.current[idx]);
      var putData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: changes.current[idx]["changed"].schema_name,
        table_name: changes.current[idx]["changed"].table_name,
        update_values: getChangeSet(
          changes.current[idx]["original"],
          changes.current[idx]["changed"],
        ),
      };
      console.log(putData);
      handleAPIActionCall(
        edaPut(userInfo.axiosInstance, UPDATE_API_ROUTE, putData),
        setSuccessMessages,
        setFailureMessages,
      );
    }
    refreshGrid();
  };

  const undoChanges = () => {
    setRowData((originalRows) => {
      const newRows = [...originalRows];
      for (const idx in changes.current) {
        newRows[idx] = Object.fromEntries(
          Object.entries(changes.current[idx]["original"]),
        );
      }
      return newRows;
    });
    changes.current = {};
    gridRef.current.api.refreshCells();
  };
  // ^^^ editing grid cells directly in grid ^^^

  // --- create or edit table item in form ---

  const handleToggleJsonFormEditor = () => {
    try {
      const _ = gridRef.current.api.getSelectedNodes()[0].data;
      setShowJsonEditorEdit((prev) => !prev);
    } catch {
      console.log("nothing selected");
    }
  };

  const handleToggleJsonFormCreator = () => {
    setShowJsonEditorCreate((prev) => !prev);
  };

  const createOrEditTableFromJsonForm = (formData) => {
    const formKeys = Object.keys(formData);
    formData["client_account_id"] = CLIENT_ACCOUNT_ID;
    BOOLEAN_COLUMNS.map((col) => {
      formData[col] = formKeys.includes(col);
    });
    console.log(formData);
    const putData = {
      client_account_id: CLIENT_ACCOUNT_ID,
      table: formData,
    };
    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, CREATE_API_ROUTE, putData),
      setSuccessMessages,
      setFailureMessages,
    );
    setShowJsonEditorEdit(false);
    setShowJsonEditorCreate(false);
    refreshGrid();
  };

  // ^^^ create or edit table item in form ^^^

  // --- trigger action button callbacks ---

  const triggerPipeline = () => {
    console.log("in trigger pipeline");
    const selectedNodes = gridRef.current.api.getSelectedNodes();

    handleAPIActionCall(
      edaPost(userInfo.axiosInstance, TRIGGER_API_ROUTE, {
        client_account_id: CLIENT_ACCOUNT_ID,
        tables: selectedNodes.map((node) => {
          return {
            schema_name: node.data.schema_name,
            table_name: node.data.table_name,
          };
        }),
      }),
      setSuccessMessages,
      setFailureMessages,
    );
  };

  const deleteTable = () => {
    console.log("in delete");
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    console.log(selectedNodes);
    selectedNodes.forEach((node) => {
      console.log(node.data);
      var deleteData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: node.data.schema_name,
        table_name: node.data.table_name,
      };
      console.log(deleteData);
      handleAPIActionCall(
        edaDelete(userInfo.axiosInstance, DELETE_API_ROUTE, deleteData),
        setSuccessMessages,
        setFailureMessages,
      );
    });
    refreshGrid();
  };

  // ^^^ trigger action button callbacks ^^^

  // --- accordion section callbacks ---

  const bulkUpdateValues = (e, formRef) => {
    console.log("Button in UpdateValuesForm was clicked!");

    const formData = new FormData(formRef.current);

    const columnName = formData.get("columnName");
    var value = formData.get("value");

    console.log(columnName, value);

    if (BOOLEAN_COLUMNS.includes(columnName)) {
      value =
        value.toLocaleLowerCase() === "on" ||
        value.toLocaleLowerCase() === "true"
          ? true
          : false;
    }

    const selectedNodes = gridRef.current.api.getSelectedNodes();

    selectedNodes.forEach((node) => {
      console.log("update node data", node.data);
      var putData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: node.data.schema_name,
        table_name: node.data.table_name,
        update_values: { [columnName]: value },
      };
      console.log(putData);
      handleAPIActionCall(
        edaPut(userInfo.axiosInstance, UPDATE_API_ROUTE, putData),
        setSuccessMessages,
        setFailureMessages,
      );
    });
    refreshGrid();
  };

  const uploadCsvToS3 = (formData) => {
    formData["client_account_id"] = CLIENT_ACCOUNT_ID;
    console.log(formData);
    handleAPIActionCall(
      edaPost(userInfo.axiosInstance, UPLOAD_CSV_API_ROUTE, formData),
      setSuccessMessages,
      setFailureMessages,
    );
    refreshGrid();
  };

  const createItemsFromCsv = (formData) => {
    formData["client_account_id"] = CLIENT_ACCOUNT_ID;
    console.log(formData);
    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, CREATE_FROM_CSV_API_ROUTE, formData),
      setSuccessMessages,
      setFailureMessages,
    );
    refreshGrid();
  };

  // ^^^ accordion section callbacks ^^^

  return (
    <div
      className="ag-theme-alpine-dark"
      style={{ height: 600, padding: "20px" }}
    >
      {hasChanges && <Alert variant="warning">Unsubmitted changes</Alert>}
      <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "5px" }}
      >
        <Button variant="quaternary" onClick={reloadData}>
          Refresh
        </Button>
      </div>
      <AgGridReact
        ref={gridRef}
        onCellValueChanged={onCellValueChanged}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection="multiple"
        animateRows={true}
        defaultColDef={defaultColDef}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        <Button
          variant="secondary"
          onClick={() => gridRef.current?.api.exportDataAsCsv()}
        >
          Export as CSV
        </Button>
        <div>
          <Button variant="quaternary" onClick={handleToggleJsonFormEditor}>
            Edit Selected
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            variant="tertiary"
            onClick={deleteTable}
          >
            Delete Selected
          </Button>
        </div>
      </div>
      <div>
        {showJsonEditorEdit && (
          <DynamicJsonForm
            title={`Edit ${
              gridRef.current.api.getSelectedNodes()[0].data.schema_name
            }.${gridRef.current.api.getSelectedNodes()[0].data.table_name}`}
            initialJsonData={gridRef.current.api.getSelectedNodes()[0].data}
            callback={createOrEditTableFromJsonForm}
          />
        )}
      </div>
      <div>
        {showJsonEditorCreate && (
          <DynamicJsonForm
            title={"Create Table Item"}
            initialJsonData={defaultTableItem}
            callback={createOrEditTableFromJsonForm}
          />
        )}
      </div>

      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!showJsonEditorEdit && !showJsonEditorCreate && (
          <StandardActionButton
            callback={submitChanges}
            text={"Submit Changes"}
          />
        )}
        <StandardActionButton
          callback={triggerPipeline}
          text={"Trigger Pipeline"}
        />
      </div>
      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StandardActionButton callback={undoChanges} text={"Undo Changes"} />
        <StandardActionButton
          callback={handleToggleJsonFormCreator}
          text={"Create Table Item"}
        />
      </div>
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Bulk Update Selected Column Values
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <UpdateValuesForm
                  onFormButtonClick={bulkUpdateValues}
                  columnNames={columnDefs.map((column) => column.columnName)}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Create Items From CSV</Accordion.Header>
            <Accordion.Body>
              <CreateFromCsv
                title={"Create Table Items From CSV"}
                callback={createItemsFromCsv}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Upload CSV For Table</Accordion.Header>
            <Accordion.Body>
              {Object.keys(csvSchemasTablesMap).length > 0 && (
                <CsvReader
                  inputMap={csvSchemasTablesMap}
                  callback={uploadCsvToS3}
                />
              )}
              {Object.keys(csvSchemasTablesMap).length === 0 && (
                <h4>No csv-curated tables</h4>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default TablesPage;
