// add widget to create items

import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect, useMemo } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import {
    edaPost,
    edaPut,
    edaDelete,
    handleAPIActionCall,
  } from "../utils/api/callapi";
  import {
    fetchAggridData,
    formatDataAggrid,
    generateAggridColumnDefs,
    orderKeysAggrid,
  } from "../utils/aggrid-helpers";

import { Button } from "react-bootstrap";
import { getChangeSet } from "../utils/common-functions";
import DynamicJsonForm from "../components/forms/DynamicJsonForm";
import { SearchBar } from "../components/forms/SearchBar";
import NumberInputForm from "../components/forms/NumberInputForm";
import { leftJoinObjectLists } from "../utils/common-functions";
import DQResultsTable from "../components/tables/DQResultsTable";
import { StandardActionButton } from "../components/StandardActionButton";

function DataqualityPage({ userInfo, setFailureMessages, setSuccessMessages }) {
  const gridRef = useRef();
  const changes = useRef({});
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [ruleset, setRuleset] = useState("");
  const [showJsonEditorCreate, setShowJsonEditorCreate] = useState(false);
  const [showJsonEditorEditRuleset, setShowJsonEditorEditRuleset] = useState(false);
  const [showJsonEditorEditItem, setShowJsonEditorEditItem] = useState(false);
  const [result, setResult] = useState(false);
  const [resultIds, setResultIds] = useState(false);
  const [gridRefreshRequired, setGridRefreshRequired] = useState(false);
  const CLIENT_ACCOUNT_ID = userInfo.client_account_id;
  const [hasChanges, setHasChanges] = useState(false);
  const DEFAULT_RULESET = "Rules = [\n\n]";

  // --- aggrid settings ---
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    editable: false,
    resizable: true,
  }));

  const PREFERRED_COLUMN_ORDER = [
    "schema_name",
    "table_name",
    "ruleset_name",
  ];

  const DISPLAY_KEYS = [
    "schema_name",
    "table_name",
    "ruleset_name",
  ];

  const BOOLEAN_COLUMNS = ["write_row_level_results"];
  // ^^^ aggrid settings ^^^

  const defaultDQItem = useMemo(() => ({
    write_row_level_results: true,
    schema_name: "",
    table_name: "",
    success_threshold: 1
  }));
  

  //
  const READ_API_ROUTE = "dataquality/listrulesets";
  const CREATE_RULESET_ITEM_API_ROUTE = "dataquality/createrulesetitem"
  const CREATE_RULESET_API_ROUTE = "dataquality/createruleset";
  const GET_RULESET_API_ROUTE = "dataquality/ruleset";
  const DELETE_RULESET_API_ROUTE = "dataquality/ruleset";
  const GET_RESULT_API_ROUTE = "dataquality/results";
  const UPDATE_API_ROUTE = "dataquality/updaterulesetitem";
  const TRIGGER_API_ROUTE = "/dataquality/run";
  const CREATE_FROM_CSV_API_ROUTE = "";
  const UPDATE_SCHEDULES_API_ROUTE = "/dataquality/setevaluationschedule"
  //


  //add a get runs/results whatever button. do that for 

  
  // --- on mount or account change ---
  useEffect(() => {
    setFailureMessages((prev) => []);
    setSuccessMessages((prev) => []);
  }, []); //clear any messages from other components on first load of this component

  useEffect(() => {
    reloadData();
  }, [userInfo]); // populate grid data on first load of this component or change in userInfo
  // ^^^ on mount or account change ^^^


  const reloadData = async () => {
    const postData = {
      client_account_id: userInfo.client_account_id,
    };

    try {
      const rowData = await fetchAggridData(
        userInfo.axiosInstance,
        READ_API_ROUTE,
        postData,
        "dq_ruleset_items",
      );
      const { formattedData, allKeys } = formatDataAggrid(rowData);
      const orderedKeys = orderKeysAggrid(PREFERRED_COLUMN_ORDER, allKeys);
      const columnDefs = generateAggridColumnDefs(orderedKeys, BOOLEAN_COLUMNS);


      setRowData((prev) => formattedData);
      setColumnDefs((prev) => columnDefs);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // ^^^ populating grid with data and setting grid properties ^^^

  // refreshing grid on certain events
  const refreshGrid = () => {
    changes.current = {};
    setHasChanges((prevData) => false);
    setGridRefreshRequired(true);
  };

  useEffect(() => {
    if (gridRefreshRequired) {
      reloadData();
      gridRef.current.api.refreshCells();
      setGridRefreshRequired(false);
    }
  }, [gridRefreshRequired]); // reload data and redraw grid when gridRefreshRequired changes to true
  // ^ refreshing grid on certain events ^

  // --- editing grid cells directly in grid ---
  const onCellValueChanged = (event) => {
    const { data, rowIndex } = event;

    if (!changes.current.hasOwnProperty(rowIndex)) {
      let originalItem = { ...data };
      originalItem[event.colDef.columnName] = event.oldValue;
      changes.current[rowIndex] = {};
      changes.current[rowIndex]["original"] = originalItem;
    }

    changes.current[rowIndex]["changed"] = event.data;
    setHasChanges((prevData) => true);
  }; // keeps track of unsubmitted changes made directly to grid. Maybe we should remove editability of grid?

  const submitChanges = () => {
    console.log("in submit changes");
    for (const idx in changes.current) {
      console.log(idx);
      console.log(changes.current[idx]);
      var putData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: changes.current[idx]["changed"].schema_name,
        table_name: changes.current[idx]["changed"].table_name,
        update_values: getChangeSet(
          changes.current[idx]["original"],
          changes.current[idx]["changed"],
        ),
      };
      console.log(putData);
      handleAPIActionCall(
        edaPut(userInfo.axiosInstance, UPDATE_API_ROUTE, putData),
        setSuccessMessages,
        setFailureMessages,
      );
    }
    refreshGrid();
  };

  const undoChanges = () => {
    setRowData((originalRows) => {
      const newRows = [...originalRows];
      for (const idx in changes.current) {
        newRows[idx] = Object.fromEntries(
          Object.entries(changes.current[idx]["original"]),
        );
      }
      return newRows;
    });
    changes.current = {};
    gridRef.current.api.refreshCells();
  };
  // ^^^ editing grid cells directly in grid ^^^

  // --- create or edit integration item in form ---

  const handleToggleEditor = async () => {
    if (!showJsonEditorEditRuleset) {
      try {
        const selectedData = gridRef.current.api.getSelectedNodes()[0].data;
        const rulesetData = await getRuleset(selectedData);
        setShowJsonEditorEditRuleset((prev) => !prev);
        setRuleset(prev => rulesetData);
      } catch (error) {
        console.log("Nothing selected or API error: ", error.message);
      }
    } else {
      setShowJsonEditorEditRuleset((prev) => !prev);
    }
  };

  const handleToggleJsonFormEditor = () => {
    try {
      const _ = gridRef.current.api.getSelectedNodes()[0].data;
      setShowJsonEditorEditItem((prev) => !prev);
    } catch {
      console.log("nothing selected");
    }
  };

  const handleToggleJsonFormCreator = () => {
    setShowJsonEditorCreate((prev) => !prev);
  };



  const createOrEditDQFromJsonForm = (formData) => {
    const formKeys = Object.keys(formData);
    formData["client_account_id"] = CLIENT_ACCOUNT_ID;
    BOOLEAN_COLUMNS.map((col) => {
      formData[col] = formKeys.includes(col);
    });
    console.log(formData);
    const putData = {
      client_account_id: CLIENT_ACCOUNT_ID,
      dq_ruleset_item: formData,
    };
    handleAPIActionCall(
      edaPost(userInfo.axiosInstance, CREATE_RULESET_ITEM_API_ROUTE, putData),
      setSuccessMessages,
      setFailureMessages,
    );
    setShowJsonEditorEditItem(false);
    setShowJsonEditorCreate(false);
    refreshGrid();
  };




  // ^^^ create or edit integration item in form ^^^

  // --- trigger action button callbacks ---

  const triggerEvaluation = () => {
    console.log("in trigger pipeline");
    const selectedNodes = gridRef.current.api.getSelectedNodes();

    console.log(selectedNodes);

    selectedNodes.map((node) => {
        console.log(node);
        handleAPIActionCall(
            edaPost(userInfo.axiosInstance, TRIGGER_API_ROUTE, {
                client_account_id: CLIENT_ACCOUNT_ID,
                schema_name: node.data.schema_name,
                table_name: node.data.table_name,
                action: "run_dataquality" 
            }),
            setSuccessMessages,
            setFailureMessages,
        )
    });
  };

  const deleteRulesetItem = () => {
    console.log("in delete");
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    console.log(selectedNodes);
    selectedNodes.forEach((node) => {
      console.log(node.data);
      var deleteData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: node.data.schema_name,
        table_name: node.data.table_name,
      };
      console.log(deleteData);
      handleAPIActionCall(
        edaDelete(userInfo.axiosInstance, DELETE_RULESET_API_ROUTE, deleteData),
        setSuccessMessages,
        setFailureMessages,
      );
    });
    refreshGrid();
  };

  const updateSchedules = () => {
    console.log("in update schedules");
    handleAPIActionCall(
      edaPost(userInfo.axiosInstance, UPDATE_SCHEDULES_API_ROUTE, {
        action: "update_dq_schedule_from_table",
        client_account_id: CLIENT_ACCOUNT_ID,
      }),
      setSuccessMessages,
      setFailureMessages,
    );
    refreshGrid();
  }; // update eventbridge rules to match schedules set in schedule column for each table


  // ^^^ trigger action button callbacks ^^^

  // --- accordion section callbacks ---

  const bulkUpdateValues = (e, formRef) => {
    console.log("Button in UpdateValuesForm was clicked!");

    const formData = new FormData(formRef.current);

    const columnName = formData.get("columnName");
    var value = formData.get("value");

    console.log(columnName, value);

    if (BOOLEAN_COLUMNS.includes(columnName)) {
      value =
        value.toLocaleLowerCase() === "on" ||
        value.toLocaleLowerCase() === "true"
          ? true
          : false;
    }

    const selectedNodes = gridRef.current.api.getSelectedNodes();

    selectedNodes.forEach((node) => {
      console.log("update node data", node.data);
      var putData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: node.data.schema_name,
        table_name: node.data.table_name,
        update_values: { [columnName]: value },
      };
      console.log(putData);
      handleAPIActionCall(
        edaPut(userInfo.axiosInstance, UPDATE_API_ROUTE, putData),
        setSuccessMessages,
        setFailureMessages,
      );
    });
    refreshGrid();
  };


  const createItemsFromCsv = (formData) => {
    formData["client_account_id"] = CLIENT_ACCOUNT_ID;
    console.log(formData);
    handleAPIActionCall(
      edaPut(userInfo.axiosInstance, CREATE_FROM_CSV_API_ROUTE, formData),
      setSuccessMessages,
      setFailureMessages,
    );
    refreshGrid();
  };





  const getRuleset = async (selection) => {
    const postData = {
        action: "get_ruleset",
        client_account_id: CLIENT_ACCOUNT_ID,
        schema_name: selection.schema_name,
        table_name: selection.table_name
    };
    console.log(postData);
    return edaPost(
        userInfo.axiosInstance,
        GET_RULESET_API_ROUTE,
        postData,
    ).then((response) => response.data.ruleset)
    .catch((error) => {
        console.log(error);
        setFailureMessages((prevFailureMessages) => [`Unable to get ruleset for ${postData.schema_name}.${postData.table_name}`]);
        return DEFAULT_RULESET;
    });
  };

  const createFromRules = (jsonFormData) => {
    const putData = {
      action: "create_ruleset",
      client_account_id: CLIENT_ACCOUNT_ID,
      schema_name: jsonFormData.schema_name,
      table_name: jsonFormData.table_name,
      rules: jsonFormData.ruleset,
    };

    handleAPIActionCall(edaPut(userInfo.axiosInstance, CREATE_RULESET_API_ROUTE, putData), setSuccessMessages, setFailureMessages);
    refreshGrid();
  };
  // ^^^ edit dq rules in form ^^^


  // --- trigger action button callbacks ---

  const autoCreateRules = () => {
    const selection = gridRef.current.api.getSelectedNodes()[0].data;
    const putData = {
      action: "create_ruleset",
      client_account_id: CLIENT_ACCOUNT_ID,
      schema_name: selection.schema_name,
      table_name: selection.table_name,
    };

    handleAPIActionCall(edaPut(userInfo.axiosInstance, CREATE_RULESET_API_ROUTE, putData), setSuccessMessages, setFailureMessages);
  };


  const getLastEvaluationResult = () => {
    if (gridRef.current.api.getSelectedNodes().length > 0) {
      const selection = gridRef.current.api.getSelectedNodes()[0].data;
      const postData = {
        client_account_id: CLIENT_ACCOUNT_ID,
        job_run_id: selection.last_job_run_id
      };

      console.log(postData);
      return edaPost(
        userInfo.axiosInstance,
        GET_RESULT_API_ROUTE,
        postData,
      ).then((response) => {
        console.log(response);
        setResult((prevData) => response.data.results[0])
      })
      .catch((error) => {
        setFailureMessages((prevMessages) => [
          ...prevMessages,
          error.response.data.message,
        ]);
      });
    }
  };

  const searchEvaluationResult = (id) => {
    const postData = {
      action: "get_result",
      client_account_id: CLIENT_ACCOUNT_ID,
    };
    console.log(id);
    if (id.includes("dqresult")) {
      postData["result_id"] = id;
    } else {
      postData["job_run_id"] = id;
    }
    console.log(postData);
    edaPost(userInfo.axiosInstance, GET_RESULT_API_ROUTE, postData)
            .then((response) => setResult((prevData) => response.data))
            .catch((error) => {
                setFailureMessages((prevMessages) => [
                  ...prevMessages,
                  error.response.data.message,
                ]);
              });
  };


  // ^^^ trigger action button callbacks ^^^


  

  

  return (
    <div
      className="ag-theme-alpine-dark"
      style={{ height: 600, padding: "20px" }}
    >
      <div
        style={{ display: "flex", justifyContent: "end", paddingTop: "5px" }}
      >
        <Button variant="quaternary" onClick={reloadData}>
          Refresh
        </Button>
      </div>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection="multiple"
        animateRows={true}
        defaultColDef={defaultColDef}
        resizable={true}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        <Button variant="quaternary" onClick={handleToggleJsonFormEditor}>
          Edit DQ Item
        </Button>
        <Button variant="quaternary" onClick={handleToggleEditor}>
          Edit Ruleset
        </Button>

        <Button
          style={{ marginLeft: "10px" }}
          variant="tertiary"
          onClick={deleteRulesetItem}
        >
          Delete Ruleset
        </Button>
      </div>
      <div>
        {showJsonEditorEditItem && (
          <DynamicJsonForm
            title={`Edit ${
              gridRef.current.api.getSelectedNodes()[0].data.schema_name
            }.${gridRef.current.api.getSelectedNodes()[0].data.table_name}`}
            initialJsonData={gridRef.current.api.getSelectedNodes()[0].data}
            callback={createOrEditDQFromJsonForm}
          />
        )}
        {showJsonEditorEditRuleset &&
          gridRef.current.api.getSelectedNodes().length > 0 && (
            <DynamicJsonForm
              title={`Edit ${
                gridRef.current.api.getSelectedNodes()[0].data.schema_name
              }.${gridRef.current.api.getSelectedNodes()[0].data.table_name}`}
              initialJsonData={{
                schema_name:
                  gridRef.current.api.getSelectedNodes()[0].data.schema_name,
                table_name:
                  gridRef.current.api.getSelectedNodes()[0].data.table_name,
                ruleset: ruleset,
              }}
              callback={createFromRules}
              allowAddField={false}
              nonEditableFields={["schema_name", "table_name"]}
              largeTextBoxKeys={["ruleset"]}
            />
          )}
      </div>
      <div>
        {showJsonEditorCreate && (
          <DynamicJsonForm
            title={"Create Dataquality Item"}
            initialJsonData={defaultDQItem}
            callback={createOrEditDQFromJsonForm}
          />
        )}
      </div>

      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!showJsonEditorEditRuleset && (<StandardActionButton callback={autoCreateRules} text={"Auto-create Rules"} />)}
        <StandardActionButton callback={triggerEvaluation} text={"Trigger Evaluation"} />
      </div>
      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StandardActionButton
          callback={updateSchedules}
          text={"Update Schedules"}
        />
        <StandardActionButton callback={getLastEvaluationResult} text={"Get Last Evaluation Results"} />
      </div>
      <div
        style={{
          paddingTop: "10px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StandardActionButton
          callback={handleToggleJsonFormCreator}
          text={"Create Dataquality Item"}
        />
      </div>
      {/* <div style={{ marginTop: "20px", paddingBottom: "20px" }}>
        <NumberInputForm
          title={"Get n last job run ids"}
          callback={getNJobRunIds}
        />
      </div> */}
      {/* <pre>{resultIds && JSON.stringify(resultIds, null, 2)}</pre>
      <div style={{ marginTop: "20px", paddingBottom: "20px" }}>
        <SearchBar
          callback={searchEvaluationResult}
          defaultText={"Get evaluation by result_id or run_id..."}
        />
      </div> */}
      <div>{result && <DQResultsTable initialJsonData={result} />}</div>
    </div>
  );
}

export default DataqualityPage;
