export const edaPost = async (instance, url_suffix, postData) => {
  const response = await instance.post(url_suffix, postData);
  return response;
};

export const edaPut = async (instance, url_suffix, putData) => {
  const response = await instance.put(url_suffix, putData);
  return response;
};

export const edaDelete = async (instance, url_suffix, deleteData) => {
  const response = await instance.delete(url_suffix, { data: deleteData });
  return response;
};

export const handleAPIActionCall = (
  apiCall,
  setSuccessMessages,
  setFailureMessages,
) => {
  apiCall
    .then((response) => {
      setSuccessMessages((prevMessages) => [
        ...prevMessages,
        response.data.message,
      ]);
    })
    .catch((error) => {
      setFailureMessages((prevMessages) => [
        ...prevMessages,
        error.response?.data.message,
      ]);
    });
};
