import React, { useEffect, useState, useRef, useMemo } from "react";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const DQResultsTable = ({ initialJsonData }) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [score, setScore] = useState(0);
  const [resultId, setResultId] = useState("");
  const [columnDefs, setColumnDefs] = useState([]);
  const [schemaName, setSchemaName] = useState("");
  const [tableName, setTableName] = useState("");
  const [evaluationTimestamp, setEvaluationTimestamp] = useState("");

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    editable: false,
    resizable: true,
  }));

  const formatRowData = (data) => {
    console.log(data);

    const displayKeys = [
      "Rule",
      "Outcome",
      "FailureReason",
      "EvaluatedMetrics",
    ];

    const formattedData = data.map((item) => {
      const filteredEntries = Object.entries(item);
      return Object.fromEntries(filteredEntries);
    });

    console.log(formattedData);

    const columnDefs = displayKeys.map((key) => {
      return {
        columnName: key,
        field: key,
        headerName: key.toLocaleLowerCase(),
        valueGetter: (params) => {
          const value = params.data[key];
          if (typeof value === "object") {
            return JSON.stringify(value);
          }
          return value !== undefined ? value : null;
        },
      };
    });

    console.log(columnDefs);

    setRowData(formattedData);
    setColumnDefs(columnDefs);
  };

  useEffect(() => {
    if (initialJsonData) {
      console.log(initialJsonData);
      setSchemaName(initialJsonData.schema_name);
      setTableName(initialJsonData.table_name);
      setEvaluationTimestamp(initialJsonData.start_time);
      setScore(initialJsonData.score * 100);
      setResultId(initialJsonData.job_run_id);
      typeof initialJsonData.results === "string"
        ? formatRowData(JSON.parse(initialJsonData.results))
        : formatRowData(initialJsonData.results);
    }
  }, [initialJsonData]);


  return (
    <div
      className="ag-theme-alpine-dark"
      style={{ height: 600, padding: "20px" }}
    >
      <h4>{schemaName}.{tableName} Dataquality Score: {score}%</h4>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        animateRows={true}
        defaultColDef={defaultColDef}
      />
      <h6>job_run_id: {resultId}</h6>
      <h6>Evaluation Time: {evaluationTimestamp}</h6>
    </div>
  );
};

export default DQResultsTable;
