import axios from "axios";

const createAxiosInstance = (token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_EDA_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      token: token,
    },
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return instance;
};

export default createAxiosInstance;
